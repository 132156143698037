<template>
  <form class="comment-form p-10 grid ggap-5 pos-r" @submit.prevent="submitAction">
    <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white-transparent" />
    <FormEditor
      label=""
      placeholder="Напишите комментарий..."
      @keydown.ctrl.enter="submitAction"
      rows="1"
      v-model="formData.message"
    />

    <!-- File list -->
    <div v-if="formData.files && formData.files.length && isShow" class="comment-form__files-list grid box overflow-a">
      <ItemFile v-for="file of formData.files" :key="file.id" :item="file" @remove="removeFile" />
    </div>

    <!-- Actions -->
    <div class="flex ai-fs jc-sb ggap-10">
      <!-- Files -->
      <div class="comment-form__files flex ai-c ggap-10">
        <input
          type="file"
          name="files"
          id="files"
          class="comment-form__files-input"
          @change="setFiles($event.target.files)"
          multiple
          accept=".svg,.jpg,.jpeg,.png,.gif,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.txt,.zip,.rar"
        />
        <label
          for="files"
          class="comment-form__files-label btn primary-light sm"
          v-tippy="'Общий максимальный размер файлов<br>не более 50мб'"
        >
          <BaseIcon class="ic-16 primary" icon="plus-circle" />Прикрепить файлы
        </label>
        <!-- Show list -->
        <button
          v-if="formData.files && formData.files.length"
          class="btn transparent-grey sm pl-10 pr-10"
          type="button"
          v-tippy="isShow ? 'Скрыть список файлов' : 'Показать список файлов'"
          @click.prevent="isShow = !isShow"
        >
          <BaseIcon class="ic-16 primary" icon="file" />
          <b>{{ formData.files.length }} шт.</b>
        </button>
      </div>

      <!-- Submit -->
      <button type="submit" class="btn sm primary" :disabled="!isActive">Отправить</button>
    </div>
  </form>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, defineEmits, nextTick, reactive, ref } from 'vue'
import { BaseLoad, BaseIcon, FormEditor, ItemFile } from '@/components'
import { cutFullName } from '@/plugins'
import events from '@/api/modules/events'
import DOMPurify from 'dompurify'

// Emits
const emits = defineEmits(['updateRows'])

// Data
const store = useStore()
const route = useRoute()
const isLoad = ref(false)
const isShow = ref(true)
const formData = reactive({
  type: 'comment',
  message: '',
  files: []
})

// Computed
// const item = computed(() => store.getters['orders/item'])
const isActive = computed(() => formData.message || formData.files?.length)
const user = computed(() => store.getters.profile)

// Methods
function submitAction() {
  const cleanedMessage = formData.message.split('<p>&nbsp;</p>').join('')
  if (formData.files?.length || cleanedMessage) {
    isLoad.value = true
    const payload = new FormData()

    if (cleanedMessage) payload.append('commentary', cleanedMessage)
    payload.append('sysadmin_request_id', route?.params?.id)

    if (formData.files?.length) {
      for (let i = 0; i < formData.files.length; i++) {
        payload.append('document[]', formData.files[i].file)
      }
    }

    events
      .comment(route?.params?.id, payload)
      .then(async () => {
        const payload = {
          before_changed: null,
          changed_fields: null,
          commentary: DOMPurify.sanitize(cleanedMessage),
          created_at: new Date(),
          documents: formData.files,
          id: 'new-message',
          system_commentary: '',
          old_fields: null,
          user: {
            ...user.value,
            fullname: cutFullName(user.value.fullname)
          }
        }
        store.commit('events/PUSH_ITEM', payload)
        await nextTick()
        emits('updateRows')
        formData.message = ' '
        formData.files = []
        await nextTick()
        formData.message = ''
        await store.dispatch('orders/GET_ITEM', route.params.id)
        formData.files = []
        formData.message = ''
      })
      .finally(() => (isLoad.value = false))
  }
}
function setFiles(files) {
  for (let i = 0; i < files.length; i++) {
    const item = files[i]
    formData.files.push({
      id: item.lastModified,
      name: item.name,
      mime: item.type,
      size: item.size,
      file: item
    })
  }
}
function removeFile(id) {
  const index = formData.files.findIndex((item) => item.id === id)
  if (index !== -1) formData.files.splice(index, 1)
}
</script>

<style lang="scss" scoped>
.comment-form {
  background-color: var(--box);
  border-top: var(--b1);

  &__files {
    &-input {
      display: none;
    }

    &-list {
      max-height: 220px;
    }
  }
}
</style>
