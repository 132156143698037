<template>
  <!-- Cancel status -->
  <ModalEmpty v-if="isOpenModalCancel" @close="isOpenModalCancel = false">
    <form
      id="form-cancel"
      class="modal-cancel grid pos-r ggap-20 p-30"
      @submit.prevent="saveCancelAction('form-cancel')"
    >
      <BaseLoad v-if="isLoad" class="white z99999" />
      <h1 class="title">Опишите причину отклонения</h1>
      <FormEditor
        label=""
        placeholder="Описание причины отклонения, например: не достаточно информации..."
        class="big"
        required
        v-model="reasonMessage"
      />
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenModalCancel = false">Отмена</button>
        <button class="btn primary" type="submit">Отправить комментарий, и отклонить заявку</button>
      </div>
    </form>
  </ModalEmpty>

  <ModalEmpty v-if="isOpenReviewers" @close="isOpenReviewers = false">
    <template class="modal-cancel pos-r grid ggap-20 p-30">
      <BaseLoad v-if="isModalLoad" class="white z99999" />
      <div class="grid gtc-1 ggap-20 ai-fs" id="takeWorkForm">
        <h1 class="title">Редактирование заявки</h1>
        <FormInput
          class="sm w-100"
          label="Название заявки"
          placeholder="-"
          v-model="takeWorkForm.name"
          @keydown.enter.prevent
          required
        />
        <Peoples
          :isRead="false"
          :isFree="isFree"
          :objUsers="peoplesSingle"
          :freeUsers="peoplesList"
          :only="'sysadmin'"
        />
      </div>
      <div class="flex ai-c jc-sb">
        <button class="btn cancel" type="button" @click.prevent="isOpenReviewers = false">Отмена</button>
        <button class="btn green" @click="saveToworkAction('takeWorkForm', false)">Сохранить</button>
      </div>
    </template>
  </ModalEmpty>

  <!-- View -->
  <ModalFull :content-class="{ sm: isNew }" @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10 jc-sb">
        <!-- Head info -->
        <div class="flex ai-c ggap-10">
          <h1 class="title">Заявка №{{ route.params && route.params.id }}</h1>
          <template v-if="item">
            <!-- Status -->
            <small
              v-if="item"
              class="marker"
              :style="{
                color: item.status && item.status.text_color,
                backgroundColor: item.status && item.status.background_color
              }"
            >
              {{ (item.status && item.status.title) || '&mdash;' }}
            </small>
          </template>
        </div>

        <!-- Head actions -->
        <div class="flex ai-c ggap-10 asd">
          <button @click="isOpenReviewers = true" class="btn gray" v-if="!isNew && isSysAdmin && !isLoad">
            <BaseIcon class="ic-20 primary" icon="edit" />
            Редактировать название и участников
          </button>
          <button @click.prevent.stop="deleteAction()" class="btn red" v-if="isNew && isAuthor && !isLoad">
            <BaseIcon class="ic-20 white" icon="delete" />
            Удалить заявку
          </button>
          <BaseDropDown
            v-if="!isNew && !isLoad && (isSysAdmin || isResponsible)"
            direction="rtl"
            btn-class="btn grey sm fw-b pl-10 pr-10"
            btn-active-class="active"
            ref="statusDropDown"
          >
            <template #btn>
              <BaseIcon class="ic-20 black" icon="more-horizontal" />
            </template>
            <template #body>
              <!-- Super lawyer -->
              <div @click.stop class="statuses-drop-down grid ggap-5 p-5">
                <template v-for="status of statusesCustom" :key="status.id">
                  <button
                    class="btn transparent-grey sm pl-10"
                    :class="{ active: item.status.id === status.id }"
                    @click="setStatusAction(status.id)"
                  >
                    <div
                      class="statuses-drop-down__dot bg-grey-dark flex ai-c jc-c"
                      :style="{ backgroundColor: status.background_color }"
                    />
                    {{ status.title }}
                  </button>
                </template>
                <hr class="m-0" />
                <template v-for="status of statusesStatic" :key="status.id">
                  <button
                    class="btn transparent-grey sm pl-10"
                    :class="{ active: item.status.id === status.id }"
                    @click="setStatusAction(status.id)"
                  >
                    <div
                      class="statuses-drop-down__dot bg-grey-dark flex ai-c jc-c"
                      :style="{ backgroundColor: status.background_color }"
                    />
                    {{ status.title }}
                  </button>
                </template>
              </div>
            </template>
          </BaseDropDown>
        </div>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="order grid" :class="{ 'gtc-1-auto': !isNew }">
        <BaseLoad v-if="isLoad" class="white z99999" />

        <!-- Has item -->
        <template v-if="item">
          <div class="order__left overflow-a h-100" id="print-content">
            <div class="order__left-content" :class="{ hide: isCollapsed, bordered: isCancel || isTowork }">
              <!-- type && project && user -->
              <section class="p-20 grid gtc-2 ggap-20">
                <ItemRead label="Тип заявки">
                  <div class="">{{ item.type?.title }}</div>
                </ItemRead>

                <ItemRead label="Проект">
                  <div class="t-upper flex ai-c ggap-5">
                    {{ item?.company?.project }}
                  </div>
                </ItemRead>

                <ItemRead label="Для кого">
                  <div class="flex ai-c ggap-5">
                    {{ item?.for_new_user ? item?.for_new_user : item?.for_user?.fullname }}
                  </div>
                </ItemRead>
              </section>

              <!-- subtype -->
              <section class="p-20 grid gtc-1 ggap-20">
                <ItemRead label="Подтип заявки">
                  <div class="break-spaces lh-24">{{ getSubTypes(item?.sub_type) }}</div>
                </ItemRead>
              </section>

              <section class="p-20 grid ggap-20">
                <ItemRead label="Комментарий инициатора">
                  <div
                    v-if="item.commentary"
                    class="editor-data break-space box p-15 pt-10 pb-10"
                    v-html="DOMPurify.sanitize(item.commentary)"
                  />
                  <template v-else>-</template>
                </ItemRead>
              </section>

              <!-- Section Documents -->
              <section class="p-20 grid ggap-20">
                <ItemRead label="Прикрепленные файлы">
                  <div v-if="item.documents && item.documents.length" class="box grid overflow-h">
                    <ItemFile
                      v-for="file of item.documents"
                      :key="file.id"
                      :item="file"
                      :btns="{ download: isDownload(file.id) }"
                    />
                  </div>
                  <BaseAlert v-else type="yellow"> Прикрепленные файлы не найдены </BaseAlert>
                </ItemRead>
              </section>

              <!-- Section Author & Responsible -->
              <section class="p-20 grid gtc-1 ggap-20">
                <ItemRead label="Инициатор">
                  <div v-if="item.owner" class="box p-5 grid gtc-auto-1 ggap-10 ai-c">
                    <div class="cover ava-50">
                      <img :src="getAvatar(item.owner.avatar)" />
                    </div>
                    <div class="flex ai-c ggap-20">
                      <div class="grid ggap-5">
                        <a :href="profileLink + item.owner.id">{{ cutFullName(item.owner.fullname) }}</a>
                        <small class="t-grey-dark">{{ item.owner.specialization || 'Нет должности' }}</small>
                      </div>
                      <button class="btn sm pl-10 pr-10 ml-auto" @click.prevent="isContactsAuthor = !isContactsAuthor">
                        контакты
                      </button>
                    </div>
                  </div>

                  <!-- Contacts -->
                  <div v-if="isContactsAuthor" class="contacts flex fw ggap-5">
                    <template
                      v-if="
                        (item?.owner?.contacts && item?.owner?.contacts.length) ||
                        (item?.contact_information && Object.keys(item?.contact_information).length)
                      "
                    >
                      <a
                        v-for="contact of item.owner.contacts"
                        :key="contact.id"
                        :href="getLink({ key: contact.type, value: contact.value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 grey" :icon="contact.type" />
                        {{ contact.value }}
                      </a>
                      <a
                        v-for="(value, key) in item?.contact_information"
                        :key="key"
                        :href="getLink({ key, value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 primary" :icon="key" />
                        {{ value }}
                      </a>
                    </template>

                    <BaseAlert v-else class="w-100" type="yellow">Контакты не найдены</BaseAlert>
                  </div>
                </ItemRead>

                <ItemRead label="Исполнитель">
                  <div v-if="item.responsible" class="box p-5 grid gtc-auto-1 ggap-10 ai-c">
                    <div class="cover ava-50">
                      <img :src="getAvatar(item.responsible.avatar)" />
                    </div>
                    <div class="flex ai-c ggap-20">
                      <div class="grid ggap-5">
                        <a :href="profileLink + item.responsible.id">
                          {{ cutFullName(item.responsible.fullname) }}
                        </a>
                        <small class="t-grey-dark">{{ item.responsible.specialization || 'Нет должности' }}</small>
                      </div>
                      <button
                        class="btn sm pl-10 pr-10 ml-auto"
                        @click.prevent="isContactsResponsible = !isContactsResponsible"
                      >
                        контакты
                      </button>
                    </div>
                  </div>
                  <template v-else>&mdash;</template>
                  <div v-if="isContactsResponsible" class="contacts flex fw ggap-5">
                    <template v-if="item?.responsible?.contacts && item?.responsible?.contacts.length">
                      <a
                        v-for="contact of item.responsible.contacts"
                        :key="contact.id"
                        :href="getLink({ key: contact.type, value: contact.value })"
                        class="contacts__item box flex ai-c ggap-5 pt-5 pb-5 pl-10 pr-10"
                      >
                        <BaseIcon class="ic-14 grey" :icon="contact.type" />
                        {{ contact.value }}
                      </a>
                    </template>

                    <BaseAlert v-else class="w-100" type="yellow">Контакты не найдены</BaseAlert>
                  </div>
                </ItemRead>

                <section class="grid gtc-1 ggap-20" v-if="item?.reviewers?.length">
                  <table class="table">
                    <tr>
                      <td>Участники</td>
                    </tr>
                    <tr v-for="person in item.reviewers" :key="person?.id" class="box">
                      <td>
                        <div class="grid gtc-auto-1 ggap-10 ai-c">
                          <div class="cover ava-30">
                            <img :src="getAvatar(person?.avatar)" />
                          </div>
                          <div class="flex ai-c ggap-20">
                            <div class="grid ggap-5">
                              <a :href="profileLink + person?.id">{{ cutFullName(person?.fullname) }}</a>
                              <small class="t-grey-dark">{{ person?.specialization || 'Нет должности' }}</small>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </section>
              </section>

              <!-- Section Dates -->
              <section class="p-20 grid gtc-3 ggap-20 b-t">
                <ItemRead label="Дата создания">
                  <div class="flex ai-c ggap-10">
                    {{ formatRuDateTime(item?.created_at) }}
                  </div>
                </ItemRead>

                <ItemRead label="Примерная дата исполнения">
                  <div class="flex ai-c ggap-10">
                    {{ item?.release_date.split(' ')[0] }}
                  </div>
                </ItemRead>
              </section>
            </div>

            <!-- Result -->
            <form id="form-result" v-if="isCancel || isTowork" class="order__left-result p-20">
              <button
                type="button"
                class="order__left-result-btn btn sm outline"
                @click.prevent.stop="isCollapsed = !isCollapsed"
              >
                {{ isCollapsed ? 'Развернуть' : 'Свернуть' }}
              </button>

              <!-- Cancel -->
              <template v-if="isCancel">
                <h3 class="title mb-10">Опишите причину отклонения</h3>
                <FormEditor
                  label=""
                  placeholder="Описание причины отклонения, например: не достаточно информации..."
                  class="big"
                  required
                  v-model="reasonMessage"
                />
              </template>

              <!-- To work -->
              <template v-if="isTowork">
                <div class="grid gtc-1 ggap-20 ai-fs" id="takeWorkForm">
                  <FormInput
                    class="sm w-100"
                    label="Название заявки"
                    placeholder="-"
                    v-model="takeWorkForm.name"
                    @keydown.enter.prevent
                    required
                  />
                  <Peoples :isRead="false" :isFree="isFree" :objUsers="peoplesSingle" :freeUsers="peoplesList" />
                </div>
              </template>
            </form>
          </div>

          <!-- Right -->
          <div v-if="!isNew && !isTowork" class="order__right overflow-h">
            <Sidebar :order="item" ref="sidebarRef" id="print-sidebar" @globaLoad="isLoad = $event" />
          </div>

          <!-- Foot -->
          <div class="order__foot pl-20 pr-20 bt">
            <!-- Cancel -->
            <div v-if="isCancel && isNew" class="flex ai-c jc-sb pb-10 pt-10">
              <button class="btn grey pl-10" @click="goBack">
                <BaseIcon class="ic-20 primary" icon="arrow-left" />
                Назад
              </button>
              <button class="btn red" @click="saveCancelAction('form-result')">Сохранить и отклонить заявку</button>
            </div>

            <!-- To work -->
            <div v-else-if="isTowork" class="flex ai-c jc-sb pb-10 pt-10">
              <button class="btn grey pl-10" @click="goBack">
                <BaseIcon class="ic-20 primary" icon="arrow-left" />
                Назад
              </button>
              <button class="btn green" @click="saveToworkAction('takeWorkForm')">Сохранить и взять в работу</button>
            </div>

            <!-- Base -->
            <div v-if="isNew && !isCancel && !isTowork && isSysAdmin" class="flex ai-c jc-sb pb-10 pt-10">
              <button class="btn red" @click="cancelAction">
                <BaseIcon class="ic-20 white" icon="close-circle" />
                Отклонить
              </button>
              <button class="btn green" @click="toworkAction">
                <BaseIcon class="ic-20 white" icon="check-circle" />
                Взять в работу
              </button>
            </div>
          </div>
        </template>
        <div v-else class="flex fd-c ai-c jc-c ggap-10">
          <BaseIcon class="ic-30 red" icon="alert-triangle" />
          <h2 class="title t-red">Ошибка загрузки</h2>
          <div>Заявки с таким номером не существует</div>
        </div>
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, ref, watch, onMounted, inject, reactive } from 'vue'
import {
  BaseLoad,
  BaseDropDown,
  ModalEmpty,
  ModalFull,
  BaseIcon,
  FormInput,
  BaseAlert,
  ItemRead,
  ItemFile,
  FormEditor,
  Peoples
} from '@/components'
import {
  isInvalidForm,
  $busEmit,
  formatRuDateTime,
  cutFullName,
  getAvatar,
  disableAllBtns,
  linksBlank
} from '@/plugins'
import Sidebar from './components/sidebar/Index.vue'
// import events from '@/api/modules/events'
import orders from '@/api/modules/orders'
import socketConnect from '@/plugins/socket-connect'
import { typeOptions } from './components/Options'
import DOMPurify from 'dompurify'

// Data
const router = useRouter()
const route = useRoute()
const store = useStore()
const profileLink = inject('profileLink')

// Data: boolean
const isFree = reactive({ reviewers: true })
const peoplesList = ref({ reviewers: [] })
const peoplesSingle = ref({ responsible: {} })
const isLoad = ref(false)
const isModalLoad = ref(false)
const isContactsAuthor = ref(false)
const isContactsResponsible = ref(false)
const isOpenModalCancel = ref(false)
const isOpenReviewers = ref(false)
const isCancel = ref(false)
const isTowork = ref(false)
const isCollapsed = ref(false)
const reasonMessage = ref('')
const sidebarRef = ref()
const statusDropDown = ref()
const takeWorkForm = ref({ name: '' })

// Computed
const profile = computed(() => store.getters.profile)
const item = computed(() => store.getters['orders/item'])
const statuses = computed(() => store.getters['helpers/statuses'] || [])
const statusesStatic = computed(() => statuses.value?.filter((el) => el.type === 'system'))
const statusesCustom = computed(() => statuses.value?.filter((el) => el.type === 'custom'))

const isNew = computed(() => item.value?.status?.id === 7)
const isResponsible = computed(() => profile.value?.id === item.value?.responsible?.id)
const isAuthor = computed(() => item.value?.owner?.id === profile.value?.id)
const isSysAdmin = computed(
  () => profile.value?.sysadmin_role === 'Sysadmin' || [1510, 1312].includes(profile.value?.id)
)

// Watch
watch(isLoad, (val) => {
  disableAllBtns('.modal', val)
})

// Created
getItem()
wsConnect()
store.dispatch('helpers/GET_STATUSES')

// Mounted
onMounted(() => {
  linksBlank()
})

// Methods
function closeAction() {
  router.replace('/orders')
}

function isDownload(fileId) {
  const index = item.value.documents.findIndex((el) => el.id === fileId)
  if (index !== -1) return true
  else if (item.value?.status?.id === 8) return true
  else return true
}

function goBack() {
  isCancel.value = false
  isTowork.value = false
  isCollapsed.value = false
}

async function getItem() {
  isLoad.value = true
  try {
    await store.dispatch('orders/GET_ITEM', route.params.id)
    takeWorkForm.value.name = item?.value?.name
    const peoples = item?.value?.reviewers?.map((el) => {
      return {
        ...el,
        text: el?.fullname
      }
    })
    peoplesList.value.reviewers = peoples
    peoplesSingle.value.responsible = {
      ...item?.value?.responsible,
      text: item?.value?.responsible?.fullname
    }
  } finally {
    isLoad.value = false
    store.dispatch('orders/GET_ROWS')
  }
}

function getLink({ key, value }) {
  switch (key) {
    case 'phone':
      return 'tel:' + value

    case 'email':
      return 'mailto:' + value

    case 'whatsapp':
      return 'https://wa.me/' + value

    case 'telegram':
      return 'tg://resolve?domain=' + value

    default:
      return value
  }
}

function getSubTypes() {
  let result = ''
  const type = typeOptions.find((el) => el?.value === item.value?.type?.value)?.value
  const ix = typeOptions.findIndex((el) => el?.value === item.value?.type?.value)
  const fields = typeOptions[ix]?.fields
  const subtype = item.value?.sub_type
  for (let i = 0; i < fields?.length; i++) {
    if (fields[i].type === 'checkbox') {
      result += fields[i].text + ': \n'
      const options = fields[i].options
      for (let n = 0; n < options.length; n++) {
        if (subtype[type][fields[i].key][options[n].value]) {
          if (options[n].withText) {
            result += `\t• ${subtype[type][fields[i].key][options[n].value]} \n`
          } else {
            result += `\t• ${[options[n].text]} \n`
          }
        }
      }
    } else if (fields[i].type === 'select') {
      for (let key in subtype[type]) {
        fields[i].key === key
        if (fields[i].key === key) {
          result += `${fields[i].text}: ${fields[i]?.options?.find((el) => el.value === subtype[type][key])?.text} \n`
        }
      }
    } else if (fields[i].type === 'text') {
      result += `${fields[i]?.text}: ${subtype?.[type]?.[fields[i]?.key] || ''}\n`
    } else if (fields[i].type === 'select-field') {
      for (let key in subtype[type]) {
        if (fields[i].key === key) {
          result += `${fields[i].text}: ${fields[i]?.options?.find((el) => el.value === subtype[type][key])?.text} \n`
        }
        if (fields[i]?.options?.find((el) => el.value === subtype[type][key])?.withText) {
          const selectFields = fields[i][subtype[type][key]]
          for (let s = 0; s < selectFields.length; s++) {
            result += `${selectFields[s].text}: ${subtype[type][selectFields[s].key]}\n`
          }
        }
      }
    } else if (fields[i].type === 'input') {
      result += `${fields[i]?.text}: ${subtype?.[type]?.[fields[i]?.key] || ''}\n`
    }
  }
  return result
}

// Methods:if status new
function cancelAction() {
  isCancel.value = true
  isCollapsed.value = true
}

function toworkAction() {
  isTowork.value = true
  isCollapsed.value = true
}

async function saveCancelAction() {
  isLoad.value = true
  const id = item.value?.id
  try {
    const form = new FormData()
    form.append('commentary', reasonMessage.value)
    form.append('status_id', 14)
    form.append('_method', 'patch')
    await orders.update(id, form)
    await store.dispatch('orders/GET_ROWS')

    closeAction()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно отклонена!'
    })
  } finally {
    isLoad.value = false
  }
}

async function saveToworkAction(formId, flag = true) {
  if (isInvalidForm(formId)) return
  isLoad.value = true
  isModalLoad.value = true
  const id = item.value?.id
  try {
    const form = new FormData()
    form.append('name', takeWorkForm.value?.name)
    form.append('responsible_id', peoplesSingle.value?.responsible?.id)
    for (let i = 0; i < peoplesList.value?.reviewers.length; i++) {
      form.append('reviewers_id[]', peoplesList.value?.reviewers[i]?.id)
    }
    flag && form.append('status_id', 8)
    form.append('_method', 'patch')
    await orders.update(id, form)
    await store.dispatch('orders/GET_ROWS')
    isCancel.value = false
    isTowork.value = false
    isCollapsed.value = false
    await getItem()
    isOpenReviewers.value = false

    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно взята в работу!'
    })
  } finally {
    isLoad.value = false
    isModalLoad.value = false
  }
}

async function setStatusAction(status) {
  if (status === 14) {
    isOpenModalCancel.value = true
    statusDropDown.value?.closeAction()
    return
  }
  try {
    isLoad.value = true
    const form = new FormData()
    form.append('status_id', status)
    form.append('_method', 'patch')
    await orders.update(item.value?.id, form)
    await store.dispatch('orders/GET_ROWS')
    await getItem()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно обновлена!'
    })
  } finally {
    isLoad.value = false
  }
}

// Methods:change status
async function deleteAction() {
  isLoad.value = true
  try {
    statusDropDown.value?.closeAction()
    await orders.delete(item.value.id)
    await store.dispatch('orders/GET_ROWS')
    closeAction()
    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: 'Заявка успешно удалена!'
    })
  } catch (err) {
    $busEmit('setToast', {
      type: 'red',
      icon: 'alert-triangle',
      message: 'Что-то пошло не так, обратитесь к администратору, или попробуйте еще раз!'
    })
  } finally {
    isLoad.value = false
  }
}

// Methods:websocket
function wsConnect() {
  const name = `private-user.${profile.value?.id}`
  socketConnect.channel(name).listen('.requestHistoryUpdated', async (data) => {
    if (route.params.id && data?.request_id === Number(route.params.id)) {
      await store.dispatch('orders/GET_ITEM', route.params.id)
    }
  })
}
</script>

<style lang="scss" scoped>
.modal {
  &-final,
  &-cancel {
    width: 600px;
  }
}
.statuses-drop-down {
  max-height: calc(100vh - 200px);
  overflow: auto;

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 100px;

    & > span {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100px;
    }
  }
}
.order {
  position: relative;
  max-height: calc(95vh - 50px);

  &__left {
    &-content {
      &.hide {
        height: 200px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: var(--form);
          opacity: 0.8;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
        }
      }

      &.bordered {
        border-bottom: var(--b1);
      }
      .contacts {
        &__item {
          text-decoration: none;
          color: var(--text);

          &:hover {
            background-color: var(--grey-l);
          }
        }
      }
    }

    &-result {
      position: relative;

      &-btn {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
    }
  }

  &__foot {
    border-top: var(--b1);
  }
}
.table {
  border-radius: 5px;
}
.table,
.table tr,
.table td {
  border: var(--b1);
  border-collapse: collapse;
  padding: 5px;
}
</style>
