<template>
  <div v-if="row && row.key" class="item-changes grid ggap-10 p-10">
    <small class="t-grey-dark fs-12">
      {{ fieldName }}
    </small>

    <!-- Objects -->
    <div v-if="row.key === 'responsible'" class="flex fw ggap-10">
      <span v-if="row.value.old" class="item-changes__box pos-r">
        <div class="item-changes__icon red" v-tippy="'Удалено'"><BaseIcon class="ic-14 white" icon="minus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: row.value.old.id,
            avatar: row.value.old.avatar,
            text: cutFullName(row.value.old.fullname),
            position: row.value.old.specialization || 'Без должности'
          }"
        />
      </span>
      <span v-if="row.value.new" class="item-changes__box pos-r">
        <div class="item-changes__icon green" v-tippy="'Добавлено'"><BaseIcon class="ic-14 white" icon="plus" /></div>
        <ItemUser
          isLink
          :isRemove="false"
          :item="{
            id: row.value.new.id,
            avatar: row.value.new.avatar,
            text: cutFullName(row.value.new.fullname),
            position: row.value.new.specialization || 'Без должности'
          }"
        />
      </span>
    </div>

    <!-- Statuses -->
    <div v-else-if="row.key === 'status_id'" class="flex ai-c ggap-10">
      <span>
        <small
          v-if="row.value.old"
          :style="{
            color: row.value.old.text_color,
            backgroundColor: row.value.old.background_color
          }"
          class="marker"
        >
          {{ row.value.old.title }}
        </small>
        <template v-else>-</template>
      </span>
      <span class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span>
        <small
          v-if="row.value.new"
          :style="{
            color: row.value.new.text_color,
            backgroundColor: row.value.new.background_color
          }"
          class="marker"
        >
          {{ row.value.new.title }}
        </small>
        <template v-else>-</template>
      </span>
    </div>

    <!-- Comment & Special conditions -->
    <div v-else-if="row.key === 'commentary' || row.key === 'special_conditions'" class="grid ggap-10">
      <span v-if="row.value.old" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.old)" />
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-down" />
      </span>
      <span v-if="row.value.new" class="flex fw ggap-10 editor-data" v-html="DOMPurify.sanitize(row.value.new)" />
    </div>

    <!-- Other -->
    <div v-else class="grid ggap-10" :class="row.value.old ? 'item-changes__gtc' : ''">
      <span v-if="row.value.old" class="flex fw ggap-10">
        {{ row.value.old ? row.value.old : '-' }}
      </span>
      <span v-if="row.value.old" class="flex ai-c jc-c">
        <BaseIcon class="ic-20 black" icon="arrow-right" />
      </span>
      <span class="flex fw ggap-10">
        {{ row.value.new ? row.value.new : '-' }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, toRefs } from 'vue'
import { BaseIcon, ItemUser } from '@/components'
import { cutFullName } from '@/plugins'

// Props
const props = defineProps(['row'])

// Data
const { row } = toRefs(props)

// Computed
const fieldName = computed(() => {
  switch (row.value.key) {
    case 'name':
      return 'Название'
    case 'status_id':
      return 'Статус'
    case 'responsible_id':
      return 'Исполнитель'
    case 'commentary':
      return 'Комментарий'
    default:
      return row.value.key
  }
})
</script>

<style lang="scss" scoped>
.item-changes {
  border-top: 1px var(--bcolor) dashed;

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: -3px;
    top: -3px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.green {
      background-color: var(--green);
    }
    &.red {
      background-color: var(--red);
    }
  }
  &__gtc {
    grid-template-columns: 1fr auto 1fr;
  }
}
</style>
